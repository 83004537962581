import React, { useContext, useEffect, useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { api, viacep, root } from "../../services/api";
import { setLanguageToI18n, translate } from "../../services/i18n";
import { Button } from "primereact/button";
import { storeSteps } from "../../services/util";
import Popup from "reactjs-popup";

import { UserContext } from "../../UserProvider";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import "./styles.scss";
import { useParams, useHistory } from "react-router-dom";
import { UTMContext } from "../../contexts/UTMContext";
import ym from "react-yandex-metrika";

export function LastStep() {
  const { utm_source, utm_medium, utm_campaign } = useContext(UTMContext);
  const { id, server, amount, gateway } = useParams();
  const [brCode, setBrCode] = useState("");
  const [data, setData] = useState({});
  const [missing, setMissing] = useState(undefined);
  const [validPhone, setValidPhone] = useState(false);
  const [validCEP, setValidCEP] = useState(false);
  const [open, setOpen] = useState(true);
  const [copied, setCopied] = useState(false);
  const history = useHistory();

  const { verifiedUsername } = useContext(UserContext);

  useEffect(() => {
    let getParams = localStorage.getItem("startParams");
    if (getParams) {
      if (JSON.parse(getParams).lang) {
        setLanguageToI18n(JSON.parse(getParams).lang);
      } else {
        setLanguageToI18n("en-US");
      }
    } else {
      setLanguageToI18n("en-US");
    }

    window.dispatchEvent(
      new CustomEvent("add-route", {
        detail: {
          label: "Confirmation",
          url: `/${server}/${id}/${amount}/${gateway}`,
          level: 4,
        },
      })
    );
    startTransaction();
  }, []);

  const handleRedirect = () => {
    setOpen(false);
    history.push({
      pathname: "/",
      search: "",
    });
  };
  const handleCopy = () => {
    navigator.clipboard.writeText(brCode);
    setCopied(true);
  };

  const modules = {
    phone: () => {
      return (
        <PhoneInput
          key="phone"
          isValid={(inputNumber, country, countries) => {
            return countries.some((country) => {
              let valid =
                inputNumber.startsWith(country.dialCode) &&
                (!country.format ||
                  (country.format + "").split(".").length - 1 ===
                    inputNumber.length);
              setValidPhone(valid);
              return valid;
            });
          }}
          country={"br"}
          preferredCountries={["br", "us"]}
          placeholder={translate("page.confirmation.phone")}
          value={data.phone}
          onChange={(phone) => setData({ ...data, phone })}
        />
      );
    },
    fullname: () => {
      return (
        <InputText
          key="Fullname"
          placeholder={translate("page.confirmation.fullname")}
          value={data.fullname}
          onChange={(fullname) =>
            setData({ ...data, fullname: fullname.target.value })
          }
        />
      );
    },
    cep: () => {
      return (
        <InputMask
          key="CEP"
          placeholder={translate("page.confirmation.cep")}
          value={data.cep}
          onChange={(cep) => {
            isCEPValid(cep.target.value);
            setData({ ...data, cep: cep.target.value });
          }}
          mask="99999-999"
        />
      );
    },
    cpf: () => {
      return (
        <InputMask
          key="CPF"
          placeholder={translate("page.confirmation.cpf")}
          value={data.cpf}
          mask="999.999.999-99"
          onChange={(cpf) => setData({ ...data, cpf: cpf.target.value })}
        />
      );
    },
  };

  function personalInformationCallback(informationComplete, ignoreFields) {
    const { data, status } = informationComplete;

    let filtered = [];
    if (data.missing)
      filtered = data.missing.filter((value) => {
        return !ignoreFields.includes(value);
      });

    if (status === 200) {
      if (data.isComplete || (filtered && filtered.length === 0)) {
        setMissing({});
        return 0;
      } else {
        if (data.message === "invalid token") {
          return 1;
        } else {
          setMissing(filtered);
          return 1;
        }
      }
    }
  }

  async function startTransaction() {
    let response = await root.get("/data/config.json");
    let storeInfo = response.data[server];
    let getParams = JSON.parse(localStorage.getItem("startParams"));
    let { username, token } = getParams;

    const params = new URLSearchParams();
    params.append("operation", "isInformationComplete");
    params.append("username", username);
    params.append("token", token);
    let informationComplete = await api.post("payment/personal-info", params);
    const isInIFrame = window.location !== window.parent.location;

    var missing_length = personalInformationCallback(
      informationComplete,
      storeInfo.ignoreFields
    );

    if (missing_length === 0) {
      const params2 = new URLSearchParams();
      params2.append("username", username);
      params2.append("sku", id);
      params2.append("server", server);
      params2.append("gateway", gateway);
      params2.append(
        "currencyCode",
        server === "globalusd" || server === "seasonusd" ? "USD" : "BRL"
      );
      params2.append("amount", amount);
      params2.append("token", token);
      params2.append("utm_source", utm_source);
      params2.append("utm_medium", utm_medium);
      params2.append("utm_campaign", utm_campaign);

      let response = await api.post("payment/test", params2);

      if (response.data.status === "fail") {
        return (window.location = `/`);
      }

      ym("reachGoal", "purchase_request");

      if (gateway === "iugu") {
        window.open(response.data.secure_url, isInIFrame ? "_self" : "_parent");
      } else if (gateway === "iugu-pix") {
        setBrCode(response.data.pix.qrcode_text);
      } else if (gateway === "omie-boleto") {
        window.location.href = response.data.cLinkBoleto;
      } else if (gateway === "omie-pix") {
        setBrCode(response.data.cCopiaCola);
      } else if (gateway === "picpay") {
        window.open(
          response.data.redirect.paymentUrl,
          isInIFrame ? "_blank" : "_parent"
        );
      } else if (gateway === "boacompra") {
        document
          .getElementById("store_id")
          .setAttribute("value", response.data.store_id);
        document
          .getElementById("return")
          .setAttribute("value", response.data.return);
        document
          .getElementById("notify_url")
          .setAttribute("value", response.data.notify_url);
        document
          .getElementById("currency_code")
          .setAttribute("value", response.data.currency_code);
        document
          .getElementById("order_id")
          .setAttribute("value", response.data.order_id);
        document
          .getElementById("order_description")
          .setAttribute("value", response.data.order_description);
        document
          .getElementById("client_email")
          .setAttribute("value", response.data.client_email);
        document
          .getElementById("amount")
          .setAttribute("value", response.data.amount);
        document
          .getElementById("hash_key")
          .setAttribute("value", response.data.hash_key);
        document
          .getElementById("country_payment")
          .setAttribute(
            "value",
            response.data.currency_code === "BRL" ? "BR" : "US"
          );

        document.getElementById("form").submit();
      } else if (gateway === "paymentwall") {
        window.open(response.data.secure_url, isInIFrame ? "_self" : "_parent");
      }
    }
  }

  function isCPFValid(value) {
    var strCPF = value.match(/\d+/g).join("");
    var Soma;
    var Resto;

    Soma = 0;
    if (strCPF === "00000000000") return false;

    for (var i = 1; i <= 9; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (i = 1; i <= 10; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }

  async function isCEPValid(value) {
    if (value) {
      var strCEP = value.match(/\d+/g).join("");
      let cepInfo = await viacep.get(`${strCEP}/json/`);
      setValidCEP(
        value.length > 0 && value.indexOf("_") === -1 && cepInfo.data.cep
          ? true
          : false
      );
    }
  }

  function validInput() {
    const validFunction = {
      cep: (value) => validCEP,
      cpf: (value) => {
        return value
          ? value.length > 0 && value.indexOf("_") === -1 && isCPFValid(value)
          : false;
      },
      fullname: (value) => {
        return value ? value.length > 0 && value.indexOf("_") === -1 : false;
      },
      phone: (value) => validPhone,
    };

    var invalids = missing.filter((miss) => {
      return !validFunction[miss](data[miss]);
    });

    return invalids.length === 0;
  }

  async function submitMissingFields() {
    const cleanFunction = {
      cep: (value) => value.match(/\d+/g).join(""),
      cpf: (value) => value.match(/\d+/g).join(""),
      fullname: (value) => value,
      phone: (value) => value.match(/\d+/g).join(""),
    };

    let getParams = JSON.parse(localStorage.getItem("startParams"));
    let { username, token } = getParams;

    const params = new URLSearchParams();
    params.append("operation", "updateInformation");
    params.append("username", username);
    params.append("token", token);
    missing.forEach((miss) => {
      params.append(miss, cleanFunction[miss](data[miss]));
    });

    await api.post("payment/personal-info", params);

    startTransaction();
  }

  return (
    <>
      {verifiedUsername &&
        (missing && Object.keys(missing).length > 0 ? (
          <div className="p-justify-center p-md-12">
            <div className="p-grid p-fluid p-md-4">
              {missing.map((item) => {
                return (
                  <div key={item} className="p-md-12">
                    {modules[item]()}
                  </div>
                );
              })}
              <div className="p-md-12">
                <Button
                  label={translate("page.confirmation.submit")}
                  disabled={!validInput()}
                  onClick={submitMissingFields}
                />
              </div>
            </div>
            {storeSteps(3)}
          </div>
        ) : gateway === "iugu-pix" || gateway === "omie-pix" ? (
          <div className="spinner-div">
            {brCode !== "" ? (
              <>
                <Popup open={open} modal closeOnDocumentClick={false}>
                  <div className="modal">
                    <button className="close" onClick={handleRedirect}>
                      &times;
                    </button>
                    <img
                      className="logo"
                      src={"/img/raidhut.png"}
                      alt="raidhut-logo"
                    />
                    <h1 className="modal-title">Pagamento via Pix</h1>
                    <h3 className="modal-subtext">
                      Abra o aplicativo de banco em seu <br /> telefone e
                      escaneie o código abaixo:
                    </h3>
                    <img
                      src={`https://gerarqrcodepix.com.br/api/v1?brcode=${brCode}`}
                      alt="Qr Code"
                    />
                    <button className="copy-button" onClick={handleCopy}>
                      {copied ? "Copiado!" : "Copiar"}
                    </button>
                  </div>
                </Popup>
                {storeSteps(3)}
              </>
            ) : (
              <ProgressSpinner />
            )}
          </div>
        ) : (
          <div className="spinner-div">
            <ProgressSpinner />
            <form
              action="https://billing.boacompra.com/payment.php"
              id="form"
              method="POST"
              name="billing"
              target="_parent"
              className="ng-untouched ng-pristine ng-valid"
            >
              <input id="store_id" name="store_id" type="hidden" value="" />
              <input id="return" name="return" type="hidden" value="" />
              <input id="notify_url" name="notify_url" type="hidden" value="" />
              <input
                id="currency_code"
                name="currency_code"
                type="hidden"
                value=""
              />
              <input id="order_id" name="order_id" type="hidden" value="" />
              <input
                id="order_description"
                name="order_description"
                type="hidden"
                value=""
              />
              <input id="amount" name="amount" type="hidden" value="" />
              <input
                id="client_email"
                name="client_email"
                type="hidden"
                value=""
              />
              <input id="hash_key" name="hash_key" type="hidden" value="" />
              <input
                id="country_payment"
                name="country_payment"
                type="hidden"
                value=""
              />
            </form>
            {storeSteps(3)}
          </div>
        ))}
    </>
  );
}
