import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { YMInitializer } from "react-yandex-metrika";

ReactDOM.render(
  <>
    <YMInitializer
      accounts={[98712745]}
      options={{
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
        ecommerce: "dataLayer",
      }}
    />
    <App />
  </>,

  document.getElementById("root")
);
